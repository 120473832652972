import React from 'react'

import { Button, Modal, Typography } from 'antd'
import gql from 'graphql-tag'
import get from 'lodash/get'
import PT from 'prop-types'
import { Field, Form } from 'react-final-form'
import validator from 'validator'

import FormCheckboxGroupInput from '../../../../../../../../../components/forms/FormCheckboxGroupInput'
import FormTableSelectorInput from '../../../../../../../../../components/forms/FormTableSelectorInput'
import FormTextInput from '../../../../../../../../../components/forms/FormTextInput'
import fullstory from '../../../../../../../../../utils/constants/fullstory'
import { hasLength, separateOutReports } from '../../../../../../../../../utils/data/utils'
import { renderReportsWithPermissions } from '../utils'

const { Text } = Typography

/**
 * Validation function
 * @param {Object} param
 */
const validate = ({ name = '' }) => ({
  name: validator.isLength(name, { max: 250, min: 1 }) ? undefined : 'Invalid Team Name',
})

const CreateTeamForm = props => {
  const { documents, members, modalProps, onSubmit, reports } = props

  const separatedReports = separateOutReports(reports)
  const reactReports = get(separatedReports, 'reactReports')
  const tableauReports = get(separatedReports, 'tableauReports')

  return (
    <Modal
      {...modalProps}
      destroyOnClose
      footer={null}
      onCancel={modalProps.closeModal}
      width={650}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                autoFocus
                label="Team Name"
                name="name"
                component={FormTextInput}
                className={fullstory.classNames.textInput}
              />
              {hasLength(documents) && (
                <Text style={{ color: 'rgba(0, 0, 0, 0.8)' }}>Documents Access & Permissions:</Text>
              )}
              {documents.map(document => (
                <Field
                  key={document.id}
                  name={`documents.${document.id}`}
                  label={document.name}
                  component={FormCheckboxGroupInput}
                  options={['DOWNLOAD']}
                />
              ))}
              {hasLength(reactReports) && (
                <Text style={{ color: 'rgba(0, 0, 0, 0.8)' }}>Reports Access Permissions:</Text>
              )}
              {renderReportsWithPermissions(reactReports, 'Radio')}
              {hasLength(tableauReports) && (
                <Text style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                  Tableau Reports Access Permissions:
                </Text>
              )}
              {renderReportsWithPermissions(tableauReports)}
              <Field
                name="members"
                label="Team Members"
                component={FormTableSelectorInput}
                columns={[
                  {
                    dataIndex: 'firstName',
                    render: (text, { lastName }) => <div>{`${text} ${lastName}`}</div>,
                    title: 'Name',
                  },
                  { dataIndex: 'email', title: 'Email' },
                ]}
                dataSource={members}
                pagination={false}
                scroll={{ y: 240 }}
              />
              <Button htmlType="submit" type="primary">
                Create Team
              </Button>
            </form>
          )
        }}
      />
    </Modal>
  )
}

CreateTeamForm.fragments = gql`
  fragment universityDocumentFields on UniversityDocument {
    id
    value: id
    name
  }

  fragment universityReportFields on UniversityReport {
    id
    value: id
    name
    allowedPermissions
    type
  }

  fragment universityClientUserFields on UniversityClientUser {
    id
    key: id
    firstName
    lastName
    email
    teams {
      id
    }
  }
`

CreateTeamForm.propTypes = {
  documents: PT.array.isRequired,
  reports: PT.array.isRequired,
}

export default CreateTeamForm
